<!--
 * @Author: mulingyuer
 * @Date: 2021-05-26 14:55:10
 * @LastEditTime: 2021-05-26 16:46:12
 * @LastEditors: mulingyuer
 * @Description: 分享数据组件
 * @FilePath: \saas-admin-vue\src\modules\portal\components\article-subject\index\CategoriesData.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="categories-data">
    <div class="content">
      <el-scrollbar wrap-class="list">
        <el-table class="thead-light" :data="tbody" style="width: 100%" stripe
          @selection-change="handleSelectionChange">
          <el-table-column v-for="th in thead" :key="th.prop" :prop="th.prop" :label="th.label"
            :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed" :show-overflow-tooltip="th.tooltip">
          </el-table-column>
          <el-table-column type="selection" width="55"></el-table-column>
        </el-table>
        <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
  import Pagination from "@/base/components/Default/Pagination";
  import {categoriesList} from "../../../api/article-subject/add-article";

  export default {
  props: {
    //加载状态 sync
    loading: {
      type: Boolean,
      required: true,
    },
    //选中的分类id数组
    categoryId: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      postData: {}, //提交的数据
      //表头
      thead: [
        { label: "文章分类", prop: "name", minWidth: 240, tooltip: true },
        { label: "分类级别", prop: "level_text", minWidth: 100, tooltip: true },
        { label: "文章数", prop: "articles_count", minWidth: 180 },
      ],
      tbody: [], //表格数据
      pageData: {}, //分页数据
    };
  },
  computed: {
    //组件内的loading
    myLoading: {
      get() {
        return this.loading;
      },
      set(val) {
        this.$emit("update:loading", val);
      },
    },
  },
  methods: {
    //获取分类列表数据
    getCategoriesList(myPrivate = false) {
      if (!myPrivate) {
        //创建时调用
        return categoriesList(this.postData).then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;
        });
      } else {
        //单独使用
        this.myLoading = true;
        categoriesList(this.postData)
          .then((res) => {
            const { data } = res;
            this.pageData = data;
            this.tbody = data.data;
            this.myLoading = false;
          })
          .catch(() => {
            this.myLoading = false;
          });
      }
    },
    //表格多选事件
    handleSelectionChange(arr) {
      if (arr.length) {
        this.$emit(
          "update:categoryId",
          arr.map((item) => item.id)
        );
      } else {
        this.$emit("update:categoryId", []);
      }
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getCategoriesList(true);
    },
  },
  async created() {
    try {
      this.myLoading = true;
      await this.getCategoriesList();
      this.myLoading = false;
    } catch (err) {
      this.$message.error(err.message);
      this.myLoading = false;
    }
  },
  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.categories-data {
  .content {
    //限制高度+显示滚动条
    ::v-deep.list {
      height: 350px;
    }
  }
}
</style>
