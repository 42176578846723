/*
 * @Author: mulingyuer
 * @Date: 2021-05-26 15:06:15
 * @LastEditTime: 2021-05-26 16:49:32
 * @LastEditors: mulingyuer
 * @Description: 添加文章弹窗
 * @FilePath: \saas-admin-vue\src\modules\portal\api\article-subject\add-article.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//来源选项数据
export const sourceAll = data => {
  return api({
    url: "/admin/portal/source/all",
    method: "post",
    data
  })
};

//获取文章列表
export const articlesList = data => {
  return api({
    url: "/admin/portal/subject/getArticles",
    method: "post",
    data
  })
};

//获取分类列表
export const categoriesList = data => {
  return api({
    url: "/admin/portal/subject/getCategories",
    method: "post",
    data
  })
};

//新增文章-保存
export const addArticle = data => {
  return api({
    url: "/admin/portal/subject/addArticle",
    method: "post",
    data
  })
};