<!--
 * @Author: mulingyuer
 * @Date: 2021-05-26 14:54:36
 * @LastEditTime: 2021-05-26 16:25:56
 * @LastEditors: mulingyuer
 * @Description: 文章数据组件
 * @FilePath: \saas-admin-vue\src\modules\portal\components\article-subject\index\ArticleData.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="article-data">
    <div class="head">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="0" size="medium" class="medium-form">
        <div class="item">
          <el-form-item prop="source_id">
            <el-select v-model="form.source_id" placeholder="请选择来源">
              <el-option v-for="op in sourcArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item prop="title">
            <el-input v-model="form.title" placeholder="请输入文章标题"></el-input>
          </el-form-item>
        </div>
        <div class="item">
          <el-button size="medium" type="primary" @click="onFilter">搜索</el-button>
          <el-button size="medium" @click="onReset('ruleForm')">重置</el-button>
        </div>
      </el-form>
    </div>
    <div class="content">
      <el-scrollbar wrap-class="list">
        <el-table class="thead-light" :data="tbody" style="width: 100%" stripe
          @selection-change="handleSelectionChange">
          <el-table-column v-for="th in thead" :key="th.prop" :prop="th.prop" :label="th.label"
            :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed" :show-overflow-tooltip="th.tooltip">
          </el-table-column>
          <el-table-column type="selection" width="55"></el-table-column>
        </el-table>
        <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
  import Pagination from "@/base/components/Default/Pagination";
  import {articlesList, sourceAll} from "../../../api/article-subject/add-article";

  export default {
  props: {
    //加载状态 sync
    loading: {
      type: Boolean,
      required: true,
    },
    //选中的文章id数组
    articleId: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      postData: {}, //提交的数据
      form: {
        source_id: "", //来源
        title: "", //文章标题
      },
      rules: {},
      sourcArr: [], //来源选项数据
      //表头
      thead: [
        { label: "文章标题", prop: "title", minWidth: 240, tooltip: true },
        { label: "来源", prop: "source_name", minWidth: 100, tooltip: true },
        { label: "发布时间", prop: "post_time", minWidth: 180 },
      ],
      tbody: [], //表格数据
      pageData: {}, //分页数据
    };
  },
  computed: {
    //组件内的loading
    myLoading: {
      get() {
        return this.loading;
      },
      set(val) {
        this.$emit("update:loading", val);
      },
    },
  },
  methods: {
    //获取来源选项数据
    getSourceAll() {
      return sourceAll().then((res) => {
        const { data } = res;
        this.sourcArr = data;
      });
    },
    //获取文章列表
    getArticlesList(myPrivate = false) {
      if (!myPrivate) {
        //创建时使用
        return articlesList(this.postData).then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;
        });
      } else {
        //单独使用
        this.myLoading = true;
        articlesList(this.postData)
          .then((res) => {
            const { data } = res;
            this.pageData = data;
            this.tbody = data.data;
            this.myLoading = false;
          })
          .catch(() => {
            this.myLoading = false;
          });
      }
    },
    //表格多选事件
    handleSelectionChange(arr) {
      if (arr.length) {
        this.$emit(
          "update:articleId",
          arr.map((item) => item.id)
        );
      } else {
        this.$emit("update:articleId", []);
      }
    },
    //筛选事件
    onFilter() {
      Object.assign(this.postData, this.form, { page: 1 });
      this.getArticlesList(true);
    },
    //重置筛选
    onReset(formName) {
      this.$refs[formName].resetFields();
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getArticlesList(true);
    },
  },
  async created() {
    try {
      this.myLoading = true;
      await this.getSourceAll();
      await this.getArticlesList();
      this.myLoading = false;
    } catch (err) {
      this.$message.error(err.message);
      this.myLoading = false;
    }
  },
  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.article-data {
  .head {
    margin-bottom: 20px;
    .medium-form {
      display: flex;
      .item {
        height: 36px;
        margin-right: 15px;
        .el-select {
          max-width: 150px;
        }
        .el-input {
          width: 240px;
        }
      }
    }
  }
  .content {
    //限制高度+显示滚动条
    ::v-deep.list {
      height: 294px;
    }
  }
}
</style>
