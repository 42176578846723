<!--
 * @Author: mulingyuer
 * @Date: 2021-05-25 17:52:07
 * @LastEditTime: 2021-05-27 11:00:15
 * @LastEditors: mulingyuer
 * @Description: 添加文章弹窗
 * @FilePath: \saas-admin-vue\src\modules\portal\components\article-subject\index\AddArticleDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="添加文章" :visible.sync="show" width="750px" class="dialog-vertical add-article-dialog"
    @open="onOpen" @close="onClose">
    <div class="content" v-loading="loading">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" size="medium"
        class="medium-form" @submit.native.prevent>
        <el-form-item label="专题栏目：" prop="new_column_id">
          <el-select v-model="form.new_column_id" placeholder="请选择专题栏目">
            <el-option v-for="op in columnsArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="tabs-content">
        <div class="left">
          <el-tabs v-model="activeTab" tab-position="left" @tab-click="onTabClick">
            <el-tab-pane v-for="tab in tabsArr" :key="tab.name" :label="tab.label" :name="tab.name">
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="right">
          <components v-if="showTabs" :is="activeTab" :loading.sync="loading" :articleId.sync="article_id"
            :categoryId.sync="category_id"></components>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button type="primary" size="small" :loading="saveLoading" @click="onConfirm('ruleForm')">确认
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import ArticleData from "./ArticleData";
  import CategoriesData from "./CategoriesData";
  import {columnsData} from "../../../api/article-subject/index";
  import {addArticle} from "../../../api/article-subject/add-article";

  export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //专题id
    topicId: {
      type: [Number, String],
      required: true,
    },
    //确认后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false, //加载中
      form: {
        new_column_id: "", //栏目id
      },
      rules: {
        new_column_id: [
          { required: true, message: "请选择专题栏目", trigger: "change" },
        ],
      },
      columnsArr: [], //栏目选项数据
      activeTab: "ArticleData", //选中的tab
      tabsArr: [
        { label: "文章数据", name: "ArticleData" },
        { label: "分类数据", name: "CategoriesData" },
      ],
      showTabs: false, //是否显示tabs
      article_id: [], //文章id数组-勾选的
      category_id: [], //分类id数组-勾选的
      saveLoading: false, //保存中
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //获取栏目选项数据
    getColumnsData() {
      this.loading = true;
      const postData = { id: this.topicId };
      return columnsData(postData).then((res) => {
        const { data } = res;
        this.columnsArr = data;
      });
    },
    //弹窗打开的回调
    async onOpen() {
      try {
        this.showTabs = true;
        await this.getColumnsData();
        this.initForm();
      } catch (err) {
        this.$message.error(err.message);
      }
    },
    //初始化表单
    initForm() {
      this.$nextTick(() => {
        //默认选择第一个栏目
        if (this.columnsArr.length) {
          this.form.new_column_id = this.columnsArr[0].id;
        }
      });
    },
    //弹窗关闭的回调
    onClose() {
      this.showTabs = false;
      this.resetData();
    },
    //重置数据
    resetData() {
      this.article_id = [];
      this.category_id = [];
    },
    //tab切换时触发
    onTabClick() {
      this.resetData();
    },
    //确认事件
    onConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = {
            new_column_id: this.form.new_column_id,
            article_id: this.article_id,
            category_id: this.category_id,
          };
          addArticle(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              this.saveLoading = false;
              this.complete(); //回调
              this.show = false; //关闭
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
  components: {
    ArticleData,
    CategoriesData,
  },
};
</script>

<style lang="scss" scoped>
.add-article-dialog {
  .content {
    .medium-form {
      .el-select--medium {
        max-width: 250px;
      }
    }
    .tabs-content {
      height: 350px;
      display: flex;
      .left {
        flex-shrink: 0;
      }
      .right {
        min-width: 0;
        flex-grow: 1;
      }
      ::v-deep.el-tabs {
        height: 100%;
        .el-tabs__item {
          width: 100px;
        }
      }
    }
  }
}
</style>
